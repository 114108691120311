import { Box } from "@mui/material";
import clsx from "clsx";
import React, { MouseEvent } from "react";
import classes from "./googleButton.module.scss";
import IconSvg from "./img/icon.svg";

interface SocialButtonProps {
  label?: string;
  disabled?: boolean;
  dark?: boolean;
  small?: boolean;
  className?: string;
  onClick?: (event: MouseEvent) => void;
}

const GoogleButton: React.FC<SocialButtonProps> = ({
  label = "Log in with Google",
  disabled,
  dark,
  className,
  onClick,
  small,
}) => {

  const handleClick = (e: React.MouseEvent) => {
    if (disabled) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    if (onClick) onClick(e);
  };

  return (
    <Box
      aria-label={label}
      onClick={handleClick}
      className={clsx(
        classes.root,
        {
          [classes.dark]: dark,
          [classes.disabled]: disabled,
          [classes.small]: small,
        },
        className
      )}
    >
      <Box className={clsx(classes.googleIcon, "oidc-google-button-icon")}>
        <IconSvg className={classes.googleIcon__svg} />
      </Box>
      <span className={clsx(classes.btnText, "oidc-google-button-label")}>{label}</span>
    </Box>
  );
};

export default GoogleButton;
